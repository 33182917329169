

































import { Component, Vue } from 'vue-property-decorator';
// import Portfolio from '@/views/Portfolio.vue';

@Component({
  components: {
    // Portfolio,
  },
})
export default class Home extends Vue {
  mounted() {
    document.title = 'Lancelot Blanchard';
  }
}
